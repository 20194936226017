





import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class Login extends Vue {
  public async mounted() {
    console.log('mounted')
    await axios({
      method: 'post',
      url: 'http://localhost:3000/api/auth/sign_in',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        gdls_code: 'hogehoge',
        password: 'password',
      },
    })
      .then((res) => {
        console.log(res)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
